import React from 'react';
import backgroundImage from '../../img/slider-2-slide-1-1920x827.jpg';
import Navbar from '../Navbar/Navbar';

function Home() {
    return (
        <div>
            <section
                className="relative bg-cover bg-center bg-no-repeat"
                style={{
                    backgroundImage: `url(${backgroundImage})`,
                }}
            >
                <div className="absolute inset-0 sm:bg-white/10 sm:bg-transparent sm:from-white/95 sm:to-white/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"></div>

                {/* Navbar component with z-index set to appear above */}
                <div className="relative z-10"><Navbar /></div>

                <div className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8">
  <div className="max-w-xl text-center ltr:sm:text-center rtl:sm:text-right">
    <h1 className="text-6xl sm:text-8xl font-extrabold text-yellow-500 mb-4">
      <strong className="block">JEWELLERY</strong>
    </h1>
    <hc className="border-white w-10 sm:w-10 mx-auto mb-6" />   <h4 className="text-3xl sm:text-4xl font-bold text-white mb-6">
     THAT YOU'LL LOVE  
    </h4>  <hr className="border-white w-10 sm:w-10 mx-auto mb-6" />

    <p className="text-lg sm:text-xl font-bold text-white">
      We provide a wide variety of jewelry, from earrings to bracelets. At Olimp, you will surely find even the rarest jewelry.
    </p>
  </div>
</div>


            </section>
        </div>
    );
}

export default Home;

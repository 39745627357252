import React from 'react';
import { FaFacebook } from 'react-icons/fa';
import { IoLogoWhatsapp, IoMdMail } from 'react-icons/io';
import { RiInstagramFill } from 'react-icons/ri';
import logo from '../../img/logo2023.jpg';

function Contact() {
    const scrollToSection = (id, event) => {
        event.preventDefault(); // Prevent the default behavior of anchor tags
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div>
            <footer className="px-4 divide-y dark:bg-gray-100 dark:text-gray-800">
                <div className="container flex flex-col justify-between py-10 mx-auto space-y-8 lg:flex-row lg:space-y-0">
                    <div className="lg:w-1/3">
                        <div className="flex items-center justify-center">
                            <img src={logo} alt="Company logo" className="w-32 h-32 object-cover " />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 text-sm gap-x-3 gap-y-8 lg:w-2/3 sm:grid-cols-4">
                        <div className="space-y-3">
                            <h3 className="tracking-wide uppercase text-red-600 font-bold">Navbar</h3>
                            <ul className="space-y-1">
                                <li>
                                    <a href="#" className="hover:text-yellow-500 font-bold" onClick={(event) => scrollToSection('home', event)}>
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hover:text-yellow-500 font-bold" onClick={(event) => scrollToSection('about', event)}>
                                        About Us
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hover:text-yellow-500 font-bold">
                                        Gallery
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="space-y-3">
                            <h3 className="tracking-wide uppercase text-red-600 font-bold">Our Shop</h3>
                            <ul className="space-y-1">
    <li>
        <p className="text-sm font-medium text-yellow-700">Contact</p>
        <a href="tel:+919765223355" className="text-gray-800 hover:text-yellow-500 font-bold">
            +91 9765223355
        </a>
    </li>
    <li>
        <p className="text-sm font-medium text-yellow-700">Address</p>
        <a href="https://maps.app.goo.gl/GHcHZiMVzesfcySi8" className="text-gray-800 hover:text-yellow-500 font-bold" target="_blank" rel="noopener noreferrer">
            Koshti Galli, Sangola, India, Maharashtra
        </a>
    </li>
</ul>

                        </div>
                        <div className="space-y-3">
                            <h3 className="tracking-wide uppercase text-red-600 font-bold">Our Design</h3>
                            <ul className="space-y-1">
                                <li>
                                    <a href="#" className="hover:text-yellow-500">
                                        Gold
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hover:text-yellow-500">
                                        Silver
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="space-y-3">
                            <div className="tracking-wide uppercase text-red-600 font-bold">Social media</div>
                            <div className="flex justify-start space-x-3">
                                <a
                                    rel="noopener noreferrer"
                                    href="https://www.facebook.com/kamblegold"
                                    title="Facebook"
                                    className="flex items-center p-1"
                                    target="_blank"
                                    >
                                    <FaFacebook className="w-5 h-5 fill-current text-gray-600 hover:text-yellow-500" />
                                </a>
                                <a
                                    rel="noopener noreferrer"
                                    href="https://wa.me/919765223355"
                                    title="WhatsApp"
                                    className="flex items-center p-1"
                                    target="_blank"
                                >
                                    <IoLogoWhatsapp className="w-5 h-5 fill-current text-gray-600 hover:text-yellow-500" />
                                </a>
                                <a
                                    rel="noopener noreferrer"
                                    href="https://www.instagram.com/kamblegold"
                                    title="Instagram"
                                    className="flex items-center p-1"
                                    target="_blank"
                                >
                                    <RiInstagramFill className="w-5 h-5 fill-current text-gray-600 hover:text-yellow-500" />
                                </a>
                                <a
                                    rel="noopener noreferrer"
                                    href="mailto:YOUR_EMAIL_ADDRESS"
                                    title="Email"
                                    className="flex items-center p-1"
                                >
                                    <IoMdMail className="w-5 h-5 fill-current text-gray-600 hover:text-yellow-500" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-sm text-blueGray-500 font-semibold py-1">
                    Copyright © 2024
                    <a href="https://www.businessmantra.info/index.php" className="text-blueGray-500 hover:text-blueGray-800">
                        Kamble Gold  All Rights Reserved To 
                    </a>
                    <a href="https://www.businessmantra.info/index.php" className="text-blueGray-500 hover:text-blueGray-800">
                         Business Mantra
                    </a>.
                </div>
            </footer>
        </div>
    );
}

export default Contact;

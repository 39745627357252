import logo from './logo.svg';
import './App.css';
import Contact from './Component/Contact/Contact';
import Navbar from './Component/Navbar/Navbar';
import Home from './Component/Home/Home';
import About from './Component/About/About';
import Gold from './Component/Body/Design/Gold';
import Silver from './Component/Body/Design/Silver';
import BodyDesign from './Component/Body/Design/BodyDesign';
import ImgDesign from './Component/Body/ImageDesign/ImgDesign';

function App() {
  return (
    <div className="App">
      <div id="home">
        <Home />
      </div>
      <div id="about">
      <About />
      </div>
      <div id="design">
      <BodyDesign />
      </div>
      <ImgDesign />
      <div id="contact">
      <Contact />
    </div>
    </div>
  );
}

export default App;

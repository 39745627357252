import React, { useState, useEffect } from 'react';
import img1 from '../../../img/SilverNeck.png';
import img2 from '../../../img/SilverBangle.png';
import img3 from '../../../img/SilverBrace.png';
import img4 from '../../../img/SilveEar.png';
import img5 from '../../../img/SilverPayal.png';
import img6 from '../../../img/SilverToeRing.png';

const Slide = ({ item, isActive }) => (
  <div
    className={`w-full  overflow-hidden text-center select-none transition duration-300 transform ease ${
      isActive ? 'translate-x-0' : 'absolute top-0 left-0 translate-x-full'
    }`}
  >
    <div
      className="w-64 h-64  rounded-full mx-auto mb-10 overflow-hidden bg-cover bg-center"
      style={{ backgroundImage: `url(${item.img})` }}
    />
    <h2 className="font-bold text-xl text-rose-700 mb-3">{item.title}</h2>
    <p className="text-sm leading-tight h-22">{item.body}</p>
  </div>
);

const Dot = ({ isActive, onClick }) => (
  <span
    className={`w-2 h-2 rounded-full mx-1 cursor-pointer ${
      isActive ? 'bg-indigo-500' : 'bg-gray-200'
    }`}
    onClick={onClick}
  />
);

const slides = [
  {
    img: img1,
    title: 'Necklace',
    body: 'You are half-full and half-empty that is what makes you a powerhouse of emotions. This necklace represents the complete circle of your different intensities in different situations.',
  },
  {
    img: img2,
    title: 'Bangles',
    body: 'Silver Enamelled Cuff Bracelet with Synthetic Blue Spinel Accents. Crafted in 925 Silver with a Shiny Oxidised Finish. Uniquely handcrafted, no two pieces are exactly alike!',
  },
  {
    img: img3,
    title: 'Bracelet',
    body: 'Silver Charm Bracelet with Lapis, Synthetic Ruby Accents and Dangling Parrot (Sudo) Charms inspired by Rabari embroidery.',
  },
  {
    img: img4,
    title: 'Earrings',
    body: 'Minimalistic Silver Drop Earrings with Heart Motifs, studded with AAA quality Cubic Zircons (CZ). Crafted in 925 Silver with a High Polish Finish. ',
  },
  {
    img: img5,
    title: 'Payals',
    body: 'Payal makes a super versatile anklet that you can style owing to its classy beads, semi-precious stones and kundan make.',
  },
  {
    img: img6,
    title: 'ToeRing',
    body: 'Silver Toe Rings (Set of two) with yellow and blue enamel Accents. Crafted in 925 Silver. It has a Dull Oxidised Finish. Uniquely handcrafted, no two pieces are exactly alike!',
  },
];

function Silver() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  const slideInterval = 3000;

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, slideInterval);

    return () => clearInterval(intervalId);
  }, [slideInterval]);

  const handleSwipe = () => {
    const diffX = touchStartX - touchEndX;
    if (diffX > -20 && diffX < 20) return;

    if (touchEndX < touchStartX) {
      setCurrentIndex((prevIndex) => Math.min(slides.length - 1, prevIndex + 1));
    } else {
      setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 1));
    }
  };

  const handleTouchStart = (event) => {
    setTouchStartX(event.touches ? event.touches[0].screenX : event.screenX);
  };

  const handleTouchEnd = (event) => {
    const endX = event.changedTouches ? event.changedTouches[0].screenX : event.screenX;
    setTouchEndX(endX);
    handleSwipe();
  };

  return (
    <div className="flex items-center justify-center px-5 py-5">
      <div className="w-full mx-auto px-10 pt-16 pb-10 text-gray-600" style={{ maxWidth: '350px' }}>
        <div className="overflow-hidden relative mb-10" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
          {slides.map((item, index) => (
            <Slide key={index} item={item} isActive={currentIndex === index} />
          ))}
        </div>
        <div className="flex justify-center">
          {slides.map((item, index) => (
            <Dot
              key={index}
              isActive={currentIndex === index}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Silver;

import React from 'react';
import Gold from './Gold';
import Silver from './Silver';
import design from '../../../img/Rivaah-Bride.jpg';
import bgImage from '../../../img/BackgroundJew.jpg'; 
function BodyDesign() {
    return (
        <div
            className="bg-cover bg-center min-h-screen" 
            style={{ backgroundImage: `url(${bgImage})` }} 
        >
            <h1 className="text-3xl font-bold mt-4 text-rose-700 text-center">
                Discover Timeless Elegance at Kamble Gold
            </h1>
            <div className="flex flex-col lg:flex-row justify-center items-center gap-8">
                <div className="w-full lg:w-1/3 p-4">
                    <Gold />
                </div>

                <div className="w-full lg:w-1/3 p-4 text-center lg:text-left">
                    <img
                        src={design}
                        alt="Design"
                        className="w-full h-auto rounded-lg shadow-lg mb-4"
                    />

                    <p className="text-lg leading-relaxed text-gray-700 font-semibold">
                        Elevate your style with our exquisite collection of gold and silver jewelry. From adorable rings and elegant earrings to statement necklaces and timeless bracelets, find the perfect piece to complement any occasion. Shop with confidence and indulge in luxury at Kamble Gold.
                    </p>
                </div>

                <div className="w-full lg:w-1/3 p-4">
                    <Silver />
                </div>
            </div>
        </div>
    );
}

export default BodyDesign;

import React, { useState } from 'react';
import logo from '../../img/Mainlogo.jpeg';

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const phoneNumber = "+919822333045";

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className={`antialiased ${menuOpen ? 'overflow-hidden' : ''}`}>
            <header>
                <nav className="flex flex-wrap items-center justify-between w-full py-4 md:py-0 px-4 text-lg text-gray-700 ">
                    <div>
                        <img src={logo} alt="Logo" className="h-36 w-auto mt-5 ml-10 " />
                    </div>
                    <div className="md:hidden">
                        <button className="block text-gray-700 hover:text-purple-400 focus:text-purple-800 focus:outline-none" onClick={toggleMobileMenu}>
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                    </div>
                    <div className={`w-full md:flex md:items-center md:w-auto ${menuOpen ? 'block' : 'hidden'}`} id="menu">
                        <ul className="pt-4 text-base text-gray-700 md:flex md:justify-between md:pt-0 md:w-full md:items-center">
                            <li>
                                <a className="md:p-4 py-2 block text-white hover:text-yellow-400 text-xl font-bold" onClick={() => scrollToSection('home')}>
                                    Home
                                </a>
                            </li>
                            <li>
                                <a className="md:p-4 py-2 block text-white hover:text-yellow-400 text-xl font-bold" onClick={() => scrollToSection('about')}>
                                    About
                                </a>
                            </li>
                            <li>
                                <a className="md:p-4 py-2 block text-white hover:text-yellow-400 text-xl font-bold" onClick={() => scrollToSection('design')}>
                                    Our Design
                                </a>
                            </li>
                            <li className="md:mr-8">
                                <a className="md:p-4 py-2 block text-white hover:text-yellow-400 text-xl font-bold" onClick={() => scrollToSection('contact')}>
                                    Contact
                                </a>
                            </li>
                           
                           
                        </ul>
                    </div>
                </nav>
            </header>
        </div>
    );
}

export default Navbar;

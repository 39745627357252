import React from 'react';
import img1 from '../../../img/him.png';
import img2 from '../../../img/icon.png';
import img3 from '../../../img/wife.png';
import img4 from '../../../img/yourself.png';
import img5 from '../../../img/trending.png';
import img6 from '../../../img/ninehues.png';

function ImgDesign() {
    return (
        <div className="container mx-auto px-4 py-8">
            <div className="flex flex-col lg:flex-row justify-center items-center gap-4 mb-8">
                <div className="flex-1">
                    <img src={img1} alt="Him" className="w-full h-auto rounded-lg shadow-lg mb-4" />
                </div>
                <div className="flex-1">
                    <img src={img2} alt="Icon" className="w-full h-auto rounded-lg shadow-lg mb-4" />
                </div>
                <div className="flex-1">
                    <img src={img3} alt="Wife" className="w-full h-auto rounded-lg shadow-lg mb-4" />
                </div>
            </div>
            <div className="flex flex-col lg:flex-row justify-center items-center gap-4">
                <div className="flex-1">
                    <img src={img4} alt="Yourself" className="w-full h-auto rounded-lg shadow-lg mb-4" />
                </div>
                <div className="flex-1">
                    <img src={img5} alt="Trending" className="w-full h-auto rounded-lg shadow-lg mb-4" />
                </div>
                <div className="flex-1">
                    <img src={img6} alt="Nine Hues" className="w-full h-auto rounded-lg shadow-lg mb-4" />
                </div>
            </div>
        </div>
    );
}

export default ImgDesign;

import React from 'react';
import about1 from '../../img/IMG_0289.jpeg';


function About() {
    return (
        <div className='flex flex-col items-center justify-center py-8 px-4 lg:flex-row lg:px-24'>
            <div className='max-w-md'>
                <h1 className='text-3xl font-bold text-yellow-500 mb-4'>About Us</h1>
                <img src={about1} alt="About" className='w-full   mb-4' />

            </div>
            <div className='lg:ml-20 text-left'>
    <h1 className='text-3xl font-bold mb-4 text-rose-700'>Kamble Gold</h1>
    <p className='text-lg text-gray-700 leading-relaxed'>
    Welcome to Kamble Gold, your trusted source for beautiful gold and silver jewelry. Since 2013, we have been the leading name in jewelry in Sangola, known for our commitment to quality and trust.    </p>
    <p className='text-lg text-gray-700 leading-relaxed'>
    Our journey began with a passion for creating timeless and elegant jewelry that showcases skilled Indian craftsmanship. Over the years, we have become a respected name in the community, thanks to our dedication to excellence and customer satisfaction.    </p>
    <p className='text-lg text-gray-700 leading-relaxed'>
    At Kamble Gold, we offer a variety of gold and silver jewelry pieces, ranging from traditional to modern styles. Whether you are looking for a special piece for yourself or a meaningful gift for someone else, we have something for everyone.    </p>
    <p className='text-lg text-gray-700 leading-relaxed'>
    Our team works hard to ensure each piece meets our high standards of purity and quality. We provide clear information about the materials and craftsmanship behind each item, so you can shop with confidence.

At Kamble Gold, our customers come first. We strive to provide a welcoming and personalized shopping experience, whether you visit our shop in person or explore our collections online. Our team is always ready to assist you and answer any questions you may have.    </p>
</div>



        </div>
    );
}

export default About;
